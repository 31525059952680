import React from 'react'
import { Link } from "gatsby"

import * as styles from '../styles/privacypolicy.module.scss'

import Layout from "../components/layout"

const PrivacyPolicy = ({location})=>{
    return(
        <Layout location = {location} title = "profile">
            <div className = {styles.privacypolicywrapper}>
                <h1>プライバシーポリシー</h1>

                    <h2>
                        運営者情報
                    </h2>
                        <p>運営者：どーじょー</p>
                        <p>連絡先：<Link to = "/form">こちらから</Link></p>

                    <h2>プライバシーポリシー</h2>
                        <h3>制定日・最終改定日</h3>
                            <p>制定日：2022年4月11日</p>
                            <p>最終改定日：2022年4月11日</p>

                        <h3>プライバシーポリシー</h3>
                        大分ツーリング（以下当ブログ）のプライバシーポリシーを次の通り記載します。
                        
                        <h3>個人情報の利用目的について</h3>
                            <p>当ブログでは、お問い合わせやコメント投稿の際に氏名・メールアドレス等の個人情報を入力いただく場合があります。</p>
                            <p>取得した個人情報は、必要な連絡のみに利用させていただくもので、これらの目的以外では利用いたしません。</p>
                        
                        <h3>個人情報の第三者開示について</h3>
                            <p>取得した個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
                            <ul>
                                <li>本人の同意が得られた場合</li>
                                <li>法令により開示が求められた場合</li>
                            </ul>

                        <h3>Cookieの使用について</h3>
                            <p>当ブログでは、広告配信やアクセス解析のためにCookieを使用しています</p>
                            <p>Cookieによりブラウザを識別していますが、特定の個人の識別はできない状態で匿名性が保たれています。</p>
                            <p>Cookieの使用を望まない場合、ブラウザからCookieを無効に設定できます。</p>

                        <h3>アクセス解析ツールについて</h3>
                            <p>当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。</p>
                            <p>このトラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>
                            <p>この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは<Link to = "https://policies.google.com/technologies/cookies?hl=ja">こちら</Link>をクリックしてください。</p>

                        <h3>第三者配信の広告サービスについて</h3>
                            <p>当ブログは、第三者配信の広告サービス「Google Adsense（グーグルアドセンス）」を利用しています。</p>
                            <p>Googleなどの第三者広告配信事業者は、訪問者の興味に応じた広告を表示するために、Cookie（当ブログの訪問者が当ブログや他のサイトにアクセスした際の情報など）を使用することがあります。</p>
                            <p>訪問者は、広告設定で訪問者に合わせた広告（以下、「パーソナライズド広告」とします。）を無効にすることが出来ます。その設定をする場合は、<Link to = "https://adssettings.google.com/authenticated">こちらをクリック</Link>してください。また、<Link to = "https://optout.aboutads.info/?c=2&lang=EN">www.aboutads.info</Link>にアクセスすれば、パーソナライズド広告に使われる第三者配信事業者の Cookie を無効にできます。</p>
                            <p>第三者配信による広告掲載を無効にしていない場合は、第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されることがあります。</p>
                            <p>Googleによって広告の第三者配信が認められている広告配信事業者の詳細は、<Link to = "https://support.google.com/admanager/answer/94149">ここをクリック</Link>してください。</p>

                    <h2>免責事項について</h2>
                        <ul>
                            <li>当ブログからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。</li>
                            <li>当ブログのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。</li>
                            <li>当ブログに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</li>
                            <li>当ブログで掲載している文章や画像などにつきましては、無断転載することを禁止します。</li>
                            <li>当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。</li>
                            <li>当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。</li>
                        </ul>

                    <h2>著作権について</h2>
                        <p>当ブログの記事について、著作権は放棄しておりません。</p>
                        <p>当ブログに存在する、文章・画像・動画等の著作物の情報を無断転載することを禁止します。引用の範囲を超えるものについては、法的処置を行います。</p>
                        <p>転載を希望される方は、<Link to = "/form">「お問い合わせ」</Link>よりご連絡をお願いします。</p>
            </div>
        </Layout>

    )
}
export default PrivacyPolicy